body {
    background-color: whitesmoke;
    font-size: 20px;
    text-align: center;
    font-family: 'Comic Sans MS', cursive, serif;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 16px solid #f3f3f3;
    border-top: 16px solid powderblue;
    border-radius: 75%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}